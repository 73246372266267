<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Axios from "@/plugins/axios";
import AccountTableCard from "@/components/dashboard/account/account-table-card.vue";
import DataLoader from "@/components/data-loader.vue";
// import UserFormModal from "@/components/dashboard/user/user-form-modal.vue";
import Pagination from "@/components/dashboard/Pagination.vue";

export default {
  components: {
    Pagination,
    DataLoader,
    AccountTableCard,
    Layout,
    PageHeader,
  },
  data() {
    return {
      accounts: [],
      showDataLoader: true,
      userFormModal: false,
    //   activeUser: null,
      next: null,
      previous: null,
      query: '',
      page: 1,
      totalItems: 0,
      url: ''
    }
  },
  mounted() {
    this.initUrl()
    this.getAccounts()
  },
  methods: {
    searchAccount(query){
      this.page = 1
      this.query = query
      this.showDataLoader = true
      this.accounts = []
      this.initUrl()
      this.getAccounts()
    },
    getAccounts(){
      Axios.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data["hydra:member"].map(a => {
              this.accounts.push(a)
            })

            this.setPagination(response)

            this.showDataLoader = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    initUrl(){
      this.url = '/admin/accounts?query=' + this.query + '&page=' + this.page 
    },
    setPagination(response){
      if (response.data["hydra:view"]["hydra:next"]) {
        this.totalItems = response.data["hydra:totalItems"]
        this.next = response.data["hydra:view"]["hydra:next"]
        this.previous = response.data["hydra:view"]["hydra:previous"]
      }
    },
    nextPage(){
      this.setCurrentPage(this.next)
      this.accounts = []
      this.getAccounts()
    },
    previousPage(){
      this.setCurrentPage(this.previous)
      this.accounts = []
      this.getAccounts()
    },
    navigate(page){
      this.page = page
      this.accounts = []
      this.showDataLoader = true
      this.initUrl()
      this.getAccounts()
    },
    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Comptes'"
        :show-add-button="false"
        @submitSearch="searchAccount"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="" v-if="accounts.length > 0 && !showDataLoader">
        <div class="card">
          <div class="card-body">
            <AccountTableCard
                :accounts="accounts"
            />
            <Pagination
                :next="next"
                :previous="previous"
                :currentPage="page"
                :totalItems="totalItems"
                @navigate="navigate"
                @nextPage="nextPage"
                @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>